<template>
  <div class="productCenter">
    <PageHeader :navData="navData"></PageHeader>
    <div :class="['title-url']">
      <div class="title">
        <span @click="goHome()">{{ $t('Home') }}</span>
        <span class="line"></span>
        <span>{{ $t('productCenter') }}</span>
      </div>
    </div>
    <div :class="['center']">
      <div class="tab-toggle">
        <div
          class="tab-item"
          :class="{ active: currentTab == index }"
          v-for="(item, index) in productType"
          @click="clickFilterProduct(item, index)"
        >
          <i
            v-if="item.productTypeName == 'productList'"
            class="el-icon-menu"
          ></i>
          <i v-else class="el-icon-s-data"></i>
          {{ $t(item.productTypeName) }}
        </div>
      </div>
      <!-- 产品列表 -->
      <div class="product-panel-box" v-if="currentTab == 0">
        <ul class="grid">
          <li v-for="item in listData" :key="item.id">
            <img :src="item.productImg" />
            <div class="info-float">
              <span class="title">{{ item.productTitle }}</span>
              <!-- <div class="desc">
                {{ item.productText }}
              </div> -->
              <router-link class="link-btn" :to="`/productDetail/${item.id}`">
                查看更多<i class="el-icon-d-arrow-right"></i>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
      <!-- 产品优势 -->
      <div v-else class="product-panel-box">
        <ProductSuperiority></ProductSuperiority>
      </div>
    </div>
  </div>
</template>

<script>
import ProductSuperiority from './lib/ProductSuperiority.vue'
export default {
  name: 'productCenter',
  data() {
    return {
      // 总页数
      total: 0,
      // 当前页
      currentPage: 1,
      pagesize: 9,
      // 产品中心的导航数据
      navData: {
        navigationDescribe: '',
        navigationImg: '',
        navigationName: ''
      },
      //产品数据
      listData: [],
      // 产品分类
      productType: [
        { productTypeName: 'productList' },
        { productTypeName: 'productSuperiority' }
        // { name: '智慧安全巡检类' },
        // { name: '智慧安全巡检+作业类' },
        // { name: '智慧搬运（AGV）类' },
        // { name: '智慧安全辅助类' }
      ],
      // 激活分类
      currentTab: 0
    }
  },
  components: {
    ProductSuperiority
  },
  created() {
    this.getNavImg()
    // this.getDataType()
    this.getData()
  },
  methods: {
    // 获取产品类型
    getDataType() {
      this.$axios
        .post('/officialweb/data/findProductTypeList', {})
        .then((res) => {
          this.productType.push(...res)
        })
    },
    // 点击筛选产品
    clickFilterProduct(type, index) {
      this.currentTab = index
      if (type.productTypeName == 'productList') {
        this.getData()
      } else {
        this.listData = []
      }
    },
    // 返回首页
    goHome() {
      this.$router.push({ path: '/' })
    },
    // 获取列表数据接口
    getData() {
      this.$axios
        .get('/officialweb/data/listProductInfoV2')
        .then((res) => {
          if (res.code == 0 && res.data) {
            this.listData = res.data
          }
        })
        .catch(() => {})
    },
    // 获取导航信息
    getNavImg() {
      this.$axios
        .post('/officialweb/data/getGwNavigation', {
          navigationType: '2'
        })
        .then((res) => {
          this.navData.navigationDescribe = res.data.navigationDescribe
          this.navData.navigationImg = res.data.navigationImg
          this.navData.navigationName = res.data.navigationName
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
// 设置分页
.bottom {
  background-color: #f6f6f6;
  .el-pagination {
    width: @mainSize;
    text-align: right;
    margin: 0 auto;
    padding: 50px 0;
  }
  /deep/ .el-pager li:hover {
    background-color: #0079cf;
    border-color: #0079cf;
    color: #fff;
  }
  /deep/ .el-pager li:active {
    border-color: #0079cf;
    color: #0079cf;
  }
  /deep/ .el-pager li {
    border: 0.0625rem solid #999;
    margin-right: 0.375rem;
    &:first-child {
      margin-left: 0.375rem;
    }
  }
  /deep/ .el-pagination.is-background .el-pager li {
    background-color: transparent;
    border: 0.0625rem solid #999;
  }
  /deep/ .el-pagination button {
    border: 0.0625rem solid #999;
  }
  /deep/ .el-pagination button:disabled {
    border: 0.0625rem solid #999;
  }
}
// 设置center
.center {
  width: 100%;
  padding: 30px;
  background-color: #f6f6f6;
  box-sizing: border-box;
  .tab-toggle {
    display: flex;
    width: @mainSize;
    margin: 0 auto;
    align-items: center;
    background-color: #004ea2;
    color: #eee;
    .tab-item {
      height: 50px;
      padding: 0 20px;
      min-width: 100px;
      line-height: 50px;
      cursor: pointer;
      &.active {
        color: #004ea2;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(5px);
      }
    }
  }
  .product-panel-box {
    width: @mainSize;
    min-height: 450px;
    margin: 0 auto;
    background-color: #fff;
    ul.grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      padding: 20px;
      box-sizing: border-box;
      li {
        width: 100%;
        height: 470px;
        overflow: hidden;
        background-color: #fff;
        cursor: pointer;
        padding: 10px;
        position: relative;
        box-sizing: border-box;
        transition: all 0.5s;
        border-radius: 10px;
        border: 1px solid #eee;
        &:hover {
          box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
          img {
            transform: scale(1.05, 1.05);
          }
          .info-float {
            bottom: 0;
          }
        }
        img {
          width: 100%;
          height: 400px;
          background-color: #fff;
          object-fit: cover;
          transition: all 0.5s;
        }
        .info-float {
          position: absolute;
          left: 0;
          bottom: 0;
          // bottom: -100px;
          width: 100%;
          color: #eee;
          background-color: rgba(0, 0, 0, 0.8);
          text-align: left;
          padding: 10px;
          box-sizing: border-box;
          backdrop-filter: blur(5px);
          transition: all 0.5s linear;
          .title {
            display: inline-block;
            width: 100%;
            color: @blueColor;
            font-weight: 700;
            line-height: 35px;
            padding-left: 10px;
            padding-right: 20px;
            box-sizing: border-box;
            letter-spacing: 0px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-left: 2px solid @mainColor;
            background-color: rgba(255, 255, 255, 0.8);
          }
          .desc {
            font-size: 12px;
            height: 40px;
            line-height: 20px;
            margin: 10px 0px;
            // text-indent: 2em;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
          }
          .link-btn {
            color: #eee;
            font-size: 14px;
            line-height: 30px;
            padding: 8px 20px;
            background-color: @blueColor;
            &:hover {
              color: @mainColor;
            }
          }
        }
      }
    }
  }
}
</style>
>
