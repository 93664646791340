<template>
  <div class="container-box">
    <div class="info-panel" v-for="item in pageData" :key="item.id">
      <div class="info-title">
        <!-- <img src="@/assets/img/gxLogo.png" /> -->
        <h3>{{ item.advTitle }}</h3>
      </div>
      <div class="info-content">
        <div class="info-text">
          <p>{{ item.advText }}</p>
        </div>
        <div class="info-sub-title" v-if="item.advSubTitle">
          {{ item.advSubTitle }}
        </div>
        <div class="media-panel-box">
          <div
            class="media-panel"
            :class="{ max: item.advDetailExt.length % 2 != 0 }"
            v-for="(obj, index) in item.advDetailExt"
            :key="index"
          >
            <div class="title" v-if="obj.title">{{ obj.title }}</div>
            <div class="content" :class="{ max: !obj.title }">
              <video
                v-if="obj.pictureType == 'mp4'"
                :src="obj.picture"
                controls
              ></video>
              <img v-else :src="obj.picture" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductSuperiority',
  data() {
    return {
      pageData: []
    }
  },
  created() {
    this.getProductSuperiority()
  },
  methods: {
    getProductSuperiority() {
      this.$axios.get('/officialweb/data/listAdvantage').then((res) => {
        if (res.code == 0 && res.data) {
          let data = res.data
          data.map((item) => {
            if (item.advDetailExt) {
              return (item.advDetailExt = JSON.parse(item.advDetailExt))
            }
          })
          this.pageData = data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container-box {
  width: 100%;
  height: 100%;
  background-color: @lightGrayColor;
}
.info-panel {
  padding: 30px 10px 10px;
  width: 100%;
  background-color: #fff;
  .info-title {
    color: @blueColor;
    height: 50px;
    padding: 10px;
    letter-spacing: 1px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    img {
      height: 30px;
      margin-right: 10px;
    }
    h3 {
      margin: 0;
      font-size: @titleSize;
      font-weight: 700;
    }
    &::after {
      content: '';
      display: block;
      width: 100px;
      height: 5px;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      background-color: @mainColor;
    }
  }
  .info-content {
    padding: 10px;
    overflow: hidden;
    .info-text {
      text-align: left;
      text-indent: 2em;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 30px;
      border-radius: 10px;
      background-color: @lightGrayColor;
      box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.1);
    }
    .info-media {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.1);
      video,
      img {
        width: 100%;
        min-height: 200px;
        margin-bottom: 10px;
        object-fit: cover;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .info-sub-title {
      font-size: 16px;
      font-weight: 700;
      color: @mainColor;
      line-height: 40px;
      border-bottom: 1px dashed #ccc;
      box-sizing: border-box;
    }
    .media-panel-box {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      .media-panel {
        width: calc(50% - 10px);
        min-height: 400px;
        overflow: hidden;
        &.max {
          width: 100%;
        }
        .title {
          height: 40px;
          line-height: 40px;
          font-size: 16px;
        }
        .content {
          width: 100%;
          height: calc(100% - 40px);
          &.max {
            height: 100%;
          }
          img,
          video {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
            border: 1px solid #eee;
          }
        }
      }
    }
  }
}
</style>
